import React from 'react';
import { graphql } from 'gatsby';

import CategoryGrid from '../components/CategoryGrid';
import SEO from '../components/SEO';

function CategoriesPage({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  return (
    <React.Fragment>
      <SEO pageTitle="Visas kategorijas" />
      <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
        Visas kategorijas
      </h1>

      <hr className="border-b border-gainsboro w-10" />

      <CategoryGrid categories={edges} />
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query CategoriesPageQuery {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(src/categories)/"}, frontmatter: {}}) {
      edges {
        node {
          frontmatter {
            url
            name
            image {
              childImageSharp {
                fluid(maxWidth: 560) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CategoriesPage;
