import React from 'react';

import Category from './Category';

function CategoryGrid({ categories }) {
  if (!categories) return null;
  return (
    <div className="flex flex-wrap -mx-6">
      {categories.map(category => (
        <Category key={category.node.frontmatter.url} {...category.node.frontmatter}></Category>
      ))}
    </div>
  );
}

export default CategoryGrid;
